import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import './component-style/all.sass'

const Header = ({ siteTitle, menuLinks }) => {
    const [active, setActive] = useState(false)
    const [navBarActiveClass, setNavBarActiveClass] = useState('')

    const toggleHamburger = () => {
        // toggle the active boolean in the state
        setActive(prev => {
            active
                ? setNavBarActiveClass('')
                : setNavBarActiveClass('is-active')
            return !active
        })
    }

    return (
        <header style={{ background: 'white', marginBottom: '1rem' }}>
            <div style={{ background: 'white' }}>
                <div
                    style={{
                        margin: '0 auto',
                        maxWidth: '90%',
                        padding: '3.45rem 1.0875rem 0.5rem',
                        display: 'flex',
                        justifyItems: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ margin: 0, flex: 1, paddingBottom: 30 }}>
                        <h1>
                            <Link
                                to="/"
                                style={{
                                    color: 'black',
                                    textDecoration: 'none',
                                    position: 'relative',
                                    zIndex: 1000
                                }}
                            >
                                {siteTitle}
                            </Link>
                        </h1>
                        <p style={{ margin: 0 }}>
                            Software Engineer and Photographer
                        </p>
                    </div>
                    <div>
                        <nav
                            className="navbar is-transparent"
                            role="navigation"
                            aria-label="main-navigation"
                        >
                            <div className="container">
                                <div className="navbar-brand">
                                    <div
                                        className={`navbar-burger burger ${navBarActiveClass}`}
                                        data-target="navMenu"
                                        onClick={toggleHamburger}
                                        onKeyDown={toggleHamburger}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                </div>
                                <div
                                    id="navMenu"
                                    className={`navbar-menu ${navBarActiveClass}`}
                                >
                                    {
                                        <div className="navbar-start has-text-centered">
                                            {menuLinks.map((link, i) => (
                                                <Link
                                                    to={link.link}
                                                    className="navbar-item"
                                                    key={i}
                                                >
                                                    {link.name}
                                                </Link>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string
}

Header.defaultProps = {
    siteTitle: ``
}

export default Header
