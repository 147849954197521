/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { SocialIcon } from 'react-social-icons'

import './component-style/stars.scss'
import Header from './header'
import './component-style/layout.css'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    menuLinks {
                        name
                        link
                    }
                }
            }
        }
    `)

    const icon_style = {
        height: 30,
        width: 30,
        margin: '0 5px'
    }

    return (
        <>
            <Header
                siteTitle={data.site.siteMetadata.title}
                menuLinks={data.site.siteMetadata.menuLinks}
            />
            <div className="parallax">
                <div id="stars1"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
            </div>
            <div
                style={{
                    margin: `0 auto`,
                    maxWidth: `90%`,
                    padding: `0 1.0875rem 1.45rem`,
                    position: 'relative'
                }}
            >
                <main>{children}</main>
                <footer style={{ paddingTop: '1rem' }}>
                    <div className="footer-copyright">
                        © {new Date().getFullYear()}, Tom Hightower
                    </div>
                    <div className="footer-social">
                        <SocialIcon
                            url="https://github.com/tom-hightower"
                            style={icon_style}
                        />
                        <SocialIcon
                            url="https://www.instagram.com/tomlapsing/"
                            style={icon_style}
                        />
                        <SocialIcon
                            url="https://www.linkedin.com/in/tom-hightower/"
                            style={icon_style}
                        />
                        <SocialIcon
                            url="https://twitter.com/tomlapsing"
                            style={icon_style}
                        />
                    </div>
                </footer>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
